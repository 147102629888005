import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

export default () => (
  <Layout>

	  <h2>Services</h2>

		<h3>Consulting</h3>

		<p>My consulting work with founders and executives explores opportunities for data science and machine learning to add value to their products and organisations. I generate ideas and deliver project plans with a business case, timeline, cost, scope and technical roadmap. I also produce hiring, training and restructuring plans for data teams to help them focus on high value activities.</p>

		<h3>Delivery contracts</h3>

		<p>I do hands on data science and machine learning delivery work. It involves finding insights and making predictions from data, designing and building complete data products and features, and working with stakeholders at all levels.</p>

		<p>My past work has included creating bespoke e-commerce recommendation systems and search engines, a machine learning system to optimise revenue for an ad tech platform, time series forecasting for a FinTech product, and route planning system to optimise operations for a prop tech team. <Link to="/case-studies/">You can see some case studies here</Link>.</p>

		<p>As well as technical delivery I work to ensure new products are understood and adopted by businesses. Bringing data science into a company is a transformational process, and new ways of working and thinking have to take root for it to be successful. I’ve written more about this in an article on <Link to="/articles/managing-data-science-projects">managing and delivering data science projects</Link>.</p>

  </Layout>
)